<template>
  <v-app-bar app class="io-navbar" elevation="0">
    <v-app-bar-nav-icon
      v-if="loggedIn"
      @click="$vuetify.breakpoint.mobile ? toggleSideBar(true) : toggleSideBar()"
    >
    </v-app-bar-nav-icon>
    <!-- <v-toolbar-title class="ml-4">
      <v-img
        v-if="!$vuetify.breakpoint.mobile"
        @click="$router.push('/videos')"
        style="cursor: pointer;"
        src="../../assets/logo_header.png"
        max-height="40"
        max-width="100"
        contain
      >

      </v-img>
    </v-toolbar-title> -->
    <v-spacer></v-spacer>
    <div v-if="loggedIn">
      <v-menu transition="scroll-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            outlined
            class="mr-5"
            style="cursor: pointer"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon class="ml-1 mr-3">mdi-account-circle</v-icon>
            <v-icon class="mr-2" size="15" style="margin-left: -5px;">mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-subheader style="height: 20px; margin-bottom: 10px; justify-content: center;">SETTINGS</v-subheader>
          <v-list-item
          v-for="setting in settings"
          :key="setting.title"
          link
          @click="handleSettingsClick(setting.key)"
          >
          <v-list-item-title v-text="setting.title"></v-list-item-title>
        </v-list-item>
        <v-divider v-if="user && user.tiimio_admin"></v-divider>
        <v-list-item
        @click="$router.push('tiimi-admin/leagues')"
        link
        v-if="user && user.tiimio_admin"
        >
        <v-list-item-title> Admin dashboard </v-list-item-title>
      </v-list-item>
      <v-subheader style="height: 20px; margin-bottom: 10px; justify-content: center;" class="mt-2">{{ $t('others') }}</v-subheader>
      <v-list-item
            link
            @click="$router.push('/exports')"
          >
            <v-list-item-title>{{ $t('exports.exports') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <span v-if="userTeams.length">
        <ChangeTeamButton></ChangeTeamButton>
      </span>
    </div>
    <v-btn @click="$router.push('/signin')" v-if="!loggedIn" color="primary" class="mr-2">{{$t('signin')}}</v-btn>
    <v-btn @click="$router.push('/login')" v-if="!loggedIn">{{$t('login')}}</v-btn>
  </v-app-bar>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import { mdiChevronDown } from "@mdi/js";
import ChangeTeamButton from '@/components/team/ChangeTeamButton'

export default {
  components: { ChangeTeamButton },
  icons: { mdiChevronDown },
  data: () => ({
  }),
  methods: {
    ...mapMutations([
      'toggleSideBar'
    ]),
    ...mapActions('user', [
      'changeCurrentTeamId',
      'logout'
    ]),
    ...mapActions('noti', [
      'showSnackbar',
    ]),
    handleSettingsClick(title) {
      switch(title) {
        case 'log_out':
          this.logout()
          this.$router.push('/login')
          break
        case 'settings':
          this.$router.push('/settings')
      }
    }
  },
  computed: {
    ...mapGetters('user', [
      'currentTeam',
      'userTeams',
      'loggedIn',
      'user'
    ]),
    settings() {
      return [
        {
          title: this.$t('navbar.settings'),
          key: "settings"
        },
        {
          title: this.$t('log_out'),
          key: "log_out"
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.io-navbar {
  // width: 100vw;ss
  order: 0;
  flex-grow: 0;
  height: 50px;
}
</style>