/* eslint-disable no-prototype-builtins */
import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '../store/index.js'
import jwt from 'jsonwebtoken'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    // roles means needed role
    // user has to be in a team
    meta: { roles: ['user'] },
    component: () => import(/* webpackChunkName: "main" */ '../views/Videos.vue')
  },
  {
    path: '/videos',
    name: 'Videos',
    children: [],
    meta: { roles: ['user'] },
    component: () => import (/* webpackChunkName: "videos" */ '../views/Videos.vue'),
  },
  {
    path: '/games',
    name: 'Games',
    children: [],
    meta: { roles: ['user'] },
    component: () => import (/* webpackChunkName: "videos" */ '../views/Games.vue'),
  },
  {
    path: '/tiimi-admin',
    name: 'TiimiAdmin',
    children: [
      {
        path: 'sports',
        component: () =>
          import ('@/components/tiimi_admin/AdminSports.vue'),
        meta: { 
          roles: ['tiimi-admin'],
          availableWithNoTeams: false
        }
      },
      {
        path: 'sports/:sport_id',
        component: () =>
          import ('@/components/tiimi_admin/Sport.vue'),
        meta: { 
          roles: ['tiimi-admin'],
          availableWithNoTeams: false
        },
        children: [
          {
            path: 'tags',
            component: () =>
              import ('@/components/tiimi_admin/sport/SportTags.vue'),
            meta: {
              roles: ['tiimi-admin'],
              availableWithNoTeams: false
            },
          },
          {
            path: 'templates',
            component: () =>
              import ('@/components/tiimi_admin/sport/SportTemplates.vue'),
            meta: { 
              roles: ['tiimi-admin'],
              availableWithNoTeams: false
            },
          },
        ]
      },
      {
        path: 'leagues',
        component: () =>
          import ('@/components/tiimi_admin/AdminLeagues.vue'),
        meta: { 
          roles: ['tiimi-admin'],
          availableWithNoTeams: false
        }
      },
      {
        path: 'leagues/:league_id',
        component: () =>
          import ('@/components/tiimi_admin/League.vue'),
        meta: { 
          roles: ['tiimi-admin'],
          availableWithNoTeams: false
        },
        children: [
          {
            path: 'games',
            component: () =>
              import ('@/components/tiimi_admin/league/LeagueGames.vue'),
            meta: { 
              roles: ['tiimi-admin'],
              availableWithNoTeams: false
            },
          },
          {
            path: 'tags',
            component: () =>
              import ('@/views/Tags.vue'),
            meta: { 
              roles: ['tiimi-admin'],
              availableWithNoTeams: false
            },
          },
          {
            path: 'teams',
            component: () =>
              import ('@/components/tiimi_admin/league/LeagueTeams.vue'),
            meta: { 
              roles: ['tiimi-admin'],
              availableWithNoTeams: false
            },
          },
          {
            path: 'clubs',
            component: () =>
              import ('@/components/tiimi_admin/league/LeagueClubs.vue'),
            meta: { 
              roles: ['tiimi-admin'],
              availableWithNoTeams: false
            },
          },
          {
            path: 'seasons',
            component: () =>
              import ('@/components/tiimi_admin/league/LeagueSeasons.vue'),
            meta: { 
              roles: ['tiimi-admin'],
              availableWithNoTeams: false
            },
          },
          {
            path: 'configuration',
            component: () =>
              import ('@/components/tiimi_admin/league/LeagueConfiguration.vue'),
            meta: { 
              roles: ['tiimi-admin'],
              availableWithNoTeams: false
            },
          },
          {
            path: 'players',
            component: () =>
              import ('@/components/tiimi_admin/league/LeaguePlayers.vue'),
            meta: { 
              roles: ['tiimi-admin'],
              availableWithNoTeams: false
            },
          },
        ]
      },
      {
        path: 'teams',
        component: () =>
          import ('@/components/tiimi_admin/Teams.vue'),
        meta: {
          roles: ['tiimi-admin']
        }
      }
    ],
    meta: { roles: ['tiimi-admin'] },
    component: () => import (/* webpackChunkName: "videos" */ '../views/TiimiAdmin.vue'),
  },
  {
    path: '/create-sport-template/:sport_id',
    name: 'Create sport ButtonTemplate',
    meta: { 
      roles: ['tiimi-admin'],
      availableWithNoTeams: false,
      hideNavBar: true,
      hideSideBar: true
    },
    component: () => import (/* webpackChunkName: "videos" */ '../components/buttontemplate/Create.vue'),
  },
  {
    path: '/create-team-template',
    name: 'Create sport ButtonTemplate',
    meta: {
      roles: ['user'],
      availableWithNoTeams: false,
      hideNavBar: true,
      hideSideBar: true
    },
    component: () => import (/* webpackChunkName: "videos" */ '../components/buttontemplate/Create.vue'),
  },
  {
    path: '/edit-button-template/:template_id/sport/:sport_id',
    name: 'Edit sport ButtonTemplate',
    meta: { 
      roles: ['tiimi-admin'],
      availableWithNoTeams: false,
      hideNavBar: true,
      hideSideBar: true
    },
    component: () => import (/* webpackChunkName: "videos" */ '../components/buttontemplate/Create.vue'),
  },
  {
    path: '/edit-button-template/:template_id/team',
    name: 'Edit sport ButtonTemplate team',
    meta: {
      roles: ['user'],
      availableWithNoTeams: false,
      hideNavBar: true,
      hideSideBar: true
    },
    component: () => import (/* webpackChunkName: "videos" */ '../components/buttontemplate/Create.vue'),
  },
  {
    path: '/confirm/:code',
    name: 'Confirm',
    children: [],
    meta: { 
      roles: ['all'], 
      availableWithNoTeams: true 
    },
    component: () => import (/* webpackChunkName: "videos" */ '../views/Confirm.vue'),
  },
  {
    path: '/filters',
    name: 'Filters',
    children: [],
    meta: { roles: ['user'] },
    component: () => import (/* webpackChunkName: "filters" */ '../views/Filters.vue'),
  },
  {
    path: '/editor',
    name: 'Editor',
    children: [],
    meta: { roles: ['user'], hideNavBar: true, mini: true },
    component: () => import (/* webpackChunkName: "filters" */ '../components/video/ClipEditor/Editor.vue'),
  },
  {
    path: '/filter/:id',
    name: 'Filter',
    children: [],
    meta: { roles: ['user'], hideNavBar: true, mini: true,  },
    component: () => import (/* webpackChunkName: "filter" */ '../views/Filter.vue'),
  },
  {
    path: '/filter/library/:id',
    name: 'Filterplay',
    children: [],
    meta: { roles: ['user'], hideNavBar: true, mini: true },
    component: () => import (/* webpackChunkName: "filter" */ '../views/Filter.vue'),
  },
  {
    path: '/team',
    name: 'Join',
    meta: { 
      roles: ['all'],
      availableWithNoTeams: true
    },
    children: [
      {
        path: 'options',
        component: () =>
          import ('@/components/team/Options.vue'),
        meta: { 
          roles: ['all'],
          availableWithNoTeams: true
        }
      },
      {
        path: 'join',
        component: () => 
          import ('@/components/team/Join.vue'),
        meta: { roles: 
          ['all'],
          availableWithNoTeams: true
        }
      },
      {
        path: 'create',
        component: () => 
          import ('@/components/team/Create.vue'),
        meta: { 
          roles: ['all'],
          availableWithNoTeams: true
        }
      }
    ],
    component: () => import ('@/views/Team.vue'),
  },
  {
    path: '/newteam',
    name: 'NewTeam',
    children: [],
    meta: { roles: ['all'] },
    component: () => import ('@/views/NewTeam.vue'),
  },
  // {
  //   path: '/maps',
  //   name: 'Maps',
  //   children: [],
  //   meta: { roles: ['user'] },
  //   component: () => import ('@/views/Maps.vue'),
  // },
  {
    path: '/buttontemplate',
    name: 'Template',
    children: [
      {
        path: 'create',
        meta: { roles: ['user'], hideNavBar: true },
        component: () => 
          import ('@/components/buttontemplate/Create.vue'),
      }
    ],
    meta: { roles: ['user'] },
    component: () => import ('@/components/buttontemplate/Create.vue'),
  },
  {
    path: '/reset/:code',
    name: 'Reset',
    children: [],
    meta: { 
      roles: ['all'],
      hideSideBar: true,
    },
    component: () => import ('@/views/Reset.vue'),
  },
  {
    path: '/videos/:id',
    name: 'Video - Videos',
    children: [],
    meta: { roles: ['user'], hideNavBar: true, mini: true },
    component: () => import ('@/views/Video.vue'),
  },
  {
    path: '/analysis/:id',
    name: 'Video - Analysis',
    children: [],
    meta: { roles: ['tiimi-admin', 'league-admin'], hideNavBar: true, mini: true },
    component: () => import ('@/views/Video.vue'),
  },
  {
    path: '/games/:id',
    name: 'Video - Games',
    children: [],
    meta: { roles: ['user'], hideNavBar: true, mini: true },
    component: () => import ('@/views/Video.vue'),
  },
  {
    path: '/about',
    name: 'About',
    meta: { roles: ['all']},
    component: () => import(/* webpackChunkName: "about" */ '../views/Videos.vue')
  },
  {
    path: '/data',
    name: 'Data',
    meta: { roles: ['all'] },
    component: () => import(/* webpackChunkName: "about" */ '../views/Data.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: { 
      roles: ['all'], 
      hideNavBar: true,
      hideSideBar: true,
      notLoggedIn: true
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/signin',
    name: 'Signin',
    meta: { 
      roles: ['all'], 
      hideNavBar: true, 
      hideSideBar: true,
      notLoggedIn: true
    },
    component: () => import(/* webpackChunkName: "signin" */ '../views/Signin.vue')
  },
  {
    path: '/popout/video/:id',
    name: 'Popout',
    meta: { 
      roles: ['user'], 
      hideNavBar: true, 
      hideSideBar: true,
      notLoggedIn: false
    },
    component: () => import(/* webpackChunkName: "signin" */ '../components/ticks/Popout.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    meta: { 
      roles: ['user'],
      hideNavBar: false,
      hideSideBar: false,
      availableWithNoTeams: true
    },
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue')
  },
  {
    path: '/plans',
    name: 'Plans',
    meta: { roles: ['user'], hideNavBar: false, hideSideBar: false },
    component: () => import(/* webpackChunkName: "plans" */ '../views/Plans.vue')
  },
  {
    path: '/share/:code',
    name: 'Share',
    meta: {
            roles: ['all'],
            hideNavBar: true,
            hideSideBar: true,
            // notLoggedIn: true 
          },
    component: () => import(/* webpackChunkName: "plans" */ '../views/Share.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    meta: { roles: ['admin'] },
    children: [
      {
        path: 'team',
        component: () => 
          import ('@/components/admin/Team.vue'),
        meta: { roles: ['admin'] }
      }
    ],
    component: () => import (/* webpackChunkName: "admin" */ '../views/Admin.vue'),
  },
  {
    path: '/tags',
    name: 'Tags',
    meta: { roles: ['user'] },
    component: () => import (/* webpackChunkName: "tags" */ '../views/Tags.vue'),
  },
  {
    path: '/refresh',
    name: 'Refresh',
    meta: { 
      roles: ['user']
    },
    component: () => import (/* webpackChunkName: "refresh" */ '../views/Refresh.vue'),
  },
  {
    path: '/forgot',
    name: 'Forgot',
    meta: {
      roles: ['all'],
      notLoggedIn: true,      
      hideSideBar: true,
    },
    component: () => import (/* webpackChunkName: "refresh" */ '../views/Forgot.vue'),
  },
  {
    path: '/library',
    name: 'Library',
    meta: { roles: ['user'] },
    component: () => import (/* webpackChunkName: "library" */ '../views/Library.vue'),
  },
  {
    path: '/presentation/:id',
    name: 'Presentation',
    meta: { 
      roles: ['user'],
      hideSideBar: true, 
      hideNavBar: true,
    },
    component: () => import (/* webpackChunkName: "presentation" */ '../views/Presentation.vue'),
  },
  {
    path: '/times',
    name: 'Times',
    meta: { roles: ['user'] },
    component: () => import (/* webpackChunkName: "times" */ '../views/Times.vue'),
  },
  {
    path: '/streaming',
    name: 'Streaming',
    meta: { 
      roles: ['user'],
      hideNavBar: true,
   },
    component: () => import ( /* webpackChunkName: "streaming" */ '../views/Streaming.vue'),
    beforeEnter: (to, _, next) => {
      const token = window.localStorage.getItem('tiimio-token') || to.query.token
      const decoded_token = jwt.decode(token)
      const currentTeam = decoded_token?.teams?.find(t => t.id == Number(decoded_token?.currentTeamId))
      if(currentTeam.streaming_enabled == 0) next('/videos')
      else next()
    }
  },
  {
    path: '/streaming/mobile',
    name: 'mobileStream',
    meta: {
      roles: ['user'],
      hideNavBar: true,
      hideSideBar: true,
    },
    component: () => import ( /* webpackChunkName: "streaming" */ '../views/Streaming.vue'),
    beforeEnter: (to, _, next) => {
      const token = window.localStorage.getItem('tiimio-token') || to.query.token
      const decoded_token = jwt.decode(token)
      const currentTeam = decoded_token?.teams?.find(t => t.id == Number(decoded_token?.currentTeamId))
      if(currentTeam.streaming_enabled == 0) next('/videos')
      else next()
    }
  },
  {
    path: '/exports',
    name: 'Exports',
    meta: { roles: ['user'] },
    component: () => import (/* webpackChunkName: "exports" */ '../views/Exports.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(( to, from, next ) => {
  const token = window.localStorage.getItem('tiimio-token') || to.query.token

  // console.log(token)
  
  const decoded_token = jwt.decode(token)

  // if the user has no token lets go to login
  if((!token || !decoded_token) &&  !to.meta.roles?.includes('all') && to.path !== '/login') {
    return next({ path: '/login', query: { ref: to.fullPath } })
  }

  const currentTeam = decoded_token?.teams?.find(t => t.id == Number(decoded_token?.currentTeamId))
  const expired = decoded_token ? decoded_token.exp - Date.now() / 1000 < 0 : null
  const isEkoris = window.location.href.includes('ekoris')
  const defaultTitle = isEkoris ? 'eKoris' : 'Tiimi.io'

  window.document.title = to.meta && to.meta.title ? to.meta.title : defaultTitle;

  // const current_team_id = decoded_token?.currentTeamId
  // const current_team_object = decoded_token?.teams?.find(t => t.id == current_team_id)
  // const current_plan_id = current_team_object?.plan_id

  // if token is expired you'll need to login again
  if(expired && !to.meta.roles?.includes('all') && to.path != '/login') {
    return next( { path: '/login', query: {exp: true, ref: to.fullPath} } )
  }

  /* ROUTES NEEDING AUTHENTICATION */

  // going to signin with a join code
  if(to.path == '/signin' && to.query.invite_code && !!decoded_token) {
    return next({ path: '/team/join', query: { invite_code: to.query.invite_code } })
  }

  // if user has no teams lets go to join
  if(token && !decoded_token.teams.length) {
    if(to.meta.availableWithNoTeams) next()
    else next({ path: '/team/options' })
    return
  }

  // has token and has teams, lets not go to routes login and signin
  if(token && !expired && to.meta.notLoggedIn) {
    return next({ path: '/videos' })
  }

  // if only for admin then check it
  if(to.meta.roles?.includes('league-admin') && !currentTeam.league_admin && !decoded_token.tiimio_admin) {
    return next({ path: '/videos' })
  }

  // if only for admin then check it
  if(to.meta.roles?.includes('tiimi-admin') && !decoded_token.tiimio_admin) {
    return next({ path: '/videos' })
  }

  /* if team has no plan let's go to plans!
     without a plan you're allowed to go to
      - notLoggedIn paths
      - create team
      - join team
      - settings
      - refresh (important)
      - reset
      - AND PLANS :D
  */

    //   if(
    // (!current_plan_id) 
    // &&
    // ( !['/settings', '/team/create', '/team/options', '/team/join', '/signin', '/login', '/forgot', '/plans', '/refresh', '/reset'].includes(to.path) )
    // && !decoded_token?.tiimio_admin
    // && !to.path.includes('/reset')
    // ) {
    //   return next({ path: '/plans' })
    // }
  // alls good lets go where we wanted

  // handle stuff for mobile app
  const message = JSON.stringify({
    route: to.path
  });

  try {
    window?.ReactNativeWebView?.postMessage(message);
  } catch(e) {
    console.log(e)
  }

  try {
    document?.ReactNativeWebView?.postMessage(message);
  } catch(e) {
    console.log(e)
  }
  
  next()
})

export default router
